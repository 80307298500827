/****DROPPED-ELEMENT-POSITION-DEFINE****/
jq3(document).on('click touch', '*[data-drop-position]', function (event) {
  var wS = jq3(window).scrollTop(),
    wH = jq3(window).height(),
    wW = jq3(window).width(),
    target = jq3(this).attr('data-drop'),
    item = jq3('#' + target),
    headerHeight = jq3('.main-header__sticky-wrapper').height(),
    iHeight = item.outerHeight(),
    iWidth = item.outerWidth(),
    eHeight = jq3(this).outerHeight(),
    eCoord = jq3(this).offset(),
    eTop = eCoord['top'],
    eLeft = eCoord['left'],
    ePosTop = eTop - wS,
    eWidth = jq3(this).outerWidth(),
    hOffset = 0,
    res = resolutionCheck(),
    additionalOffset01 = 0,
    additionalOffset02 = 0;

  if (item.find('.dropdown__check-list').length) {
    if (item.find('.form-item-wrap--event-search').length) {
      additionalOffset01 = item
        .find('.form-item-wrap--event-search')
        .outerHeight();
    }
    if (item.find('.dropdown__controls').length) {
      additionalOffset02 = item.find('.dropdown__controls').outerHeight();
    }
    item.find('.dropdown__check-list').css({
      'max-height':
        wH - ePosTop - eHeight - additionalOffset01 - additionalOffset02 + 'px',
    });
  }

  if (res == 32) {
    hOffset = 24;
  } else {
    hOffset = 32;
  }

  /**********VERTICAL-POSITION-DEFINE*************/
  /************drop-down***********/
  if (wH - ePosTop > iHeight) {
    item.addClass('under').removeClass('above');
  } else if (wH - ePosTop <= iHeight && ePosTop - headerHeight > iHeight) {
    /************drop-up***********/
    item.addClass('above').removeClass('under');
  } else if (wH - ePosTop <= iHeight && ePosTop < iHeight) {
    /***********drop-neutral*****************/
    item.addClass('under').removeClass('above');
  }
  /**************HORIZONTAL-POSITION**************/

  if (wW - eLeft >= iWidth) {
    item.addClass('right').removeClass('left neutral');
  } else if (
    wW - eLeft < iWidth &&
    eLeft + iWidth >= wW &&
    eLeft + eWidth < iWidth
  ) {
    item
      .removeClass('left right')
      .addClass('neutral')
      .css('right', -(wW - (eLeft + eWidth + hOffset)) + 'px');
  } else if (
    wW - eLeft < iWidth &&
    eLeft + iWidth >= wW &&
    eLeft + eWidth >= iWidth
  ) {
    item.addClass('left').removeClass('right neutral');    
  }
});
/****POPOVER-ELEMENT-POSITION-DEFINE****/
function definePoistion(element, target, type, direction) {
  var wS = jq3(window).scrollTop(),
    wH = jq3(window).height(),
    wW = jq3(window).width(),
    eHeight = element.outerHeight(),
    eWidth = element.outerWidth(),
    eCoord = element.offset(),
    eTop = eCoord['top'],
    eLeft = eCoord['left'],
    ePosTop = eTop - wS,
    item = jq3(target).length ? target : jq3('#' + target),
    iHeight = item.outerHeight(),
    iWidth = item.outerWidth(),
    indent;

  if (element.parents('.txt').length) {
    indent = 7;
  } else if (type == 'submenu') {
    indent = 2;
  } else {
    indent = 15;
  }
  /*************VERTICAL-POSITION-DEFINE*************/

  if (direction != 'horizontal') {
    if (type == 'submenu') {
      item.css({
        top: 0 + 'px',
      });
    } else if (ePosTop >= iHeight + 20) {
      /************drop-up***********/
      item
        .addClass('above')
        .removeClass('under')
        .css({
          top: eTop - iHeight - indent + 'px',
        });
    } else if (ePosTop < iHeight + 20 && wH - ePosTop > iHeight + 20) {
      /************drop-down***********/
      item
        .addClass('under')
        .removeClass('above')
        .css({
          top: eTop + eHeight + indent + 'px',
        });
    } else if (wH - ePosTop <= iHeight + 20 && ePosTop < iHeight + 20) {
      /*********drop-neutral*************/
      item
        .addClass('under')
        .removeClass('above')
        .css({
          top: eTop + eHeight + indent + 'px',
        });
    }
  }

  /**************HORIZONTAL-POSITION-DEFINE**************/
  if (type == 'tooltip' && direction == 'vertical') {
    item.css({
      left: eLeft + eWidth / 2 - iWidth / 2 + 'px',
    });
    if (iWidth > eWidth && eLeft < (iWidth - eWidth) / 2) {
      item.css({
        left: eLeft + 'px',
      });
    }
    /*         if (iWidth > eWidth && (wW - (eLeft + eWidth)) < (iWidth - eWidth) / 2) {
					item.css({
							left: wW - (eLeft + eWidth) + 'px'
					});
			} */
  } else if (type == 'tooltip' && direction == 'horizontal') {
    item.removeClass('under above').css({
      top: eTop + eHeight / 2 - iHeight / 2 + 'px',
    });

    if (wW - eLeft - eWidth >= iWidth + 30) {
      item
        .css({
          left: eLeft + eWidth + 10 + 'px',
        })
        .removeClass('left neutral')
        .addClass('right');
    } else if (wW - eLeft - eWidth < iWidth + 30 && iWidth + 10 <= eLeft) {
      /************drop-down***********/
      item
        .css({
          left: eLeft - (iWidth + 10) + 'px',
        })
        .removeClass('right neutral')
        .addClass('left');
    } else if (wW - eLeft - eWidth < iWidth + 30 && iWidth + 10 > eLeft) {
      /*********drop-neutral*************/
      item.removeClass('left').addClass('neutral right');
    }
  }

  if (type == 'popover') {
    if (eLeft + iWidth <= wW) {
      item
        .removeClass('left')
        .addClass('right')
        .css({
          left: eLeft + 'px',
        });
    } else if (eLeft + iWidth > wW && eLeft + eWidth >= iWidth) {
      item
        .removeClass('right')
        .addClass('left')
        .css({
          left: eLeft + eWidth - iWidth + 'px',
        });
    } else if (eLeft + iWidth > wW && eLeft + eWidth < iWidth) {
      item
        .removeClass('right')
        .addClass('left')
        .css({
          'max-width': eLeft + eWidth + 'px',
          left: eLeft + eWidth - iWidth + 'px',
        });
    }
  } else if (type == 'submenu') {
    item.css({
      left: eWidth + indent + 'px',
    });
  }
}
