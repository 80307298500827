var dH = Math.max(
  document.body.scrollHeight,
  document.documentElement.scrollHeight,
  document.body.offsetHeight,
  document.documentElement.offsetHeight,
  document.body.clientHeight,
  document.documentElement.clientHeight
);
var currentScroll = 0;
var lastScrollTop = 0;

var toggleIndicator = true;
var slideToggleIndicator = true;
/**********common functions*****************/
function stopPropagation() {
  jq3(document).on('click touch', '.stppg', function (event) {
    event.stopPropagation();
  });
}
function resolutionCheck() {
  if (jq3('.i').length > 0) {
    var res = parseInt(jq3('.i').css('width'));
    return res;
  }
}
function openDataOpen() {
  jq3(document).on('click', '*[data-open]', function () {
    var arr = jq3(this).attr('data-open');
    var target = arr.split(' ');
    for (var i = 0; i < target.length; i++) {
      jq3('#' + target[i]).addClass('active');
      if (jq3('#' + target[i]).find('.styler').length) {
        setTimeout(function () {
          jq3('.styler').trigger('refresh');
        }, 100);
      }
      if (jq3('#' + target[i]).find('.js-filter-list-multiple-columns').length) {
        filterListColumnsHeight();
      }      
    }
  });
  jq3(document).on('click', '*[data-open*="mdl"]', function () {
    if (!jq3(this).hasClass('w-mdl-close')) {
      let isFull = jq3(this).data('open').includes('full');

      if (jq3('.touchevents').length || isFull) {
        overlayStart();
      }
    }
  });
}
function playVideo() {
  jq3(document).on('click', '*[data-play]', function () {
    var target = jq3(this).attr('data-play');
    var videoEl = jq3('#' + target);
    var container = jq3('#' + target).parent('.video-wrapper');
    let timeout;

    const handleMouse = (e, target) => {
      if (
        (e.type === 'mouseenter' || e.type === 'mousemove') &&
        !jq3(target).hasClass('highlight')
      ) {
        jq3(target).addClass('highlight');
        timeout = setTimeout(() => {
          jq3(target).removeClass('highlight');
        }, 5000);
      }
      if (e.type === 'mouseleave') {
        jq3(target).removeClass('highlight');
        if (timeout) clearTimeout(timeout);
      }
    };

    const handleVideo = () => {
      videoEl.on('play', function () {
        jq3(container).removeClass('paused');
      });
      videoEl.on('pause', function () {
        jq3(container).addClass('paused');
      });
      videoEl.on('ended', function () {
        jq3(container).removeClass('active paused highlight');
        jq3(container).off();
      });
    };

    if (videoEl.is('video')) {
      handleVideo();

      if (jq3(container).hasClass('active')) {
        if (jq3(container).hasClass('paused')) {
          videoEl[0].play();
        } else {
          videoEl[0].pause();
        }
      } else {
        videoEl[0].play();
        jq3(container).addClass('active');
        jq3(container).on('mouseenter mouseleave mousemove', function (e) {
          handleMouse(e, this);
        });
      }
    }

    if (videoEl.is('iframe')) {
      jq3(container).addClass('active');
    }
  });
}
function closeDataClose() {
  jq3(document).on('click', '*[data-close]', function () {
    var arr = jq3(this).attr('data-close');
    var target = arr.split(' ');
    for (var i = 0; i < target.length; i++) {
      jq3('#' + target[i]).removeClass('active');
    }
  });
}
function closeHagerty() {
  jq3(document).on('click', '*[data-close-h]', function (event) {
    /* 		jq3('#mainnav').removeClass('show-bg'); */
    event.stopPropagation();
    var arr = jq3(this).attr('data-close-h');
    var target = arr.split(' ');
    var targetClass = jq3(this).attr('data-class');
    for (var i = 0; i < target.length; i++) {
      jq3('#' + target[i]).removeClass('"' + targetClass + '"');
    }
  });
}
function closeMDL() {
  jq3(document).on('click', '.w-mdl-close', function () {
    if (jq3('.mdl-wrap.active').length > 1) {
      if (jq3(this).hasClass('mdl-wrap')) {
        jq3(this).removeClass('active');
      } else {
        jq3(this).closest('.mdl-wrap').removeClass('active');
      }
    } else {
      if (jq3(this).hasClass('mdl-wrap')) {
        jq3(this).removeClass('active');
      } else {
        jq3(this).closest('.mdl-wrap').removeClass('active');
      }
      if (
        jq3('.touchevents').length ||
        jq3(this).closest('.mdl-wrap').hasClass('mdl-wrap--full-screen')
      ) {
        overlayEnd();
      }
    }
  });
  jq3(document).on('click', '.mdl', function (event) {
    event.stopPropagation();
  });
}

function drop() {
  jq3(document).on('click touch', '*[data-drop]', function () {
    var target = jq3(this).attr('data-drop');
    jq3('.w-dropdown:not(#' + target + ')').removeClass('active');
    jq3('*[data-drop]').not(this).removeClass('active');
    if (!jq3('#' + target).hasClass('active')) {
      jq3('#' + target).addClass('active');
      jq3(this).addClass('active');
    } else {
      jq3('#' + target).removeClass('active');
      jq3(this).removeClass('active');
    }
  });
}
function closeDropdown() {
  jq3(document).on('click touch', function (event) {
    if (jq3(event.target).closest('*[data-drop]').length) {
      return;
    }
    jq3('.w-dropdown').removeClass('active');
    jq3('*[data-drop]').removeClass('active');
    // event.stopPropagation();
  });
}
jq3(document).on('click touch', '.w-dropdown', function (event) {
  if (jq3('.w-dropdown').hasClass('active')) {
    event.stopPropagation();
  }
});
function tooltipToggle() {
  jq3(document).on('mouseenter mouseout', '*[data-hover]', function () {
    var target = jq3(this).attr('data-hover'),
      item = jq3('#' + target);
    if (!item.hasClass('active')) {
      item.addClass('active');
    } else {
      item.removeClass('active');
    }
  });
}

jq3(document).on('mouseenter', '*[data-hover]', function (event) {
  event.stopPropagation();
  var element = jq3(this),
    target = jq3(this).attr('data-hover');
  definePoistion(element, target, 'tooltip');
});
jq3(document).on('click touch', '*[data-popover-position]', function (event) {
  event.stopPropagation();
  var element = jq3(this),
    target = jq3(this).attr('data-drop');
  definePoistion(element, target, 'popover');
});

function toggle() {
  jq3(document).on('click', '*[data-toggle]', function (event) {
    event.stopPropagation();
    var toggleIndicator = true;
    if (toggleIndicator == true) {
      toggleIndicator = false;
      var arr = jq3(this).attr('data-toggle');
      var target = arr.split(' ');
      for (var i = 0; i < target.length; i++) {
        if (!jq3('#' + target[i]).hasClass('active')) {
          jq3('#' + target[i]).addClass('active');
          jq3(this).addClass('active');
          setTimeout(function () {
            toggleIndicator = true;
          }, 250);
          if (jq3('#' + target[i]).find('.styler').length) {
            setTimeout(function () {
              jq3('.styler').trigger('refresh');
            }, 100);
          }
        } else {
          jq3('#' + target[i]).removeClass('active');
          jq3(this).removeClass('active');
          setTimeout(function () {
            toggleIndicator = true;
          }, 250);
        }
      }
    }
  });
}
function slideToggleFunction() {
  jq3(document).on('click', '*[data-slide]', function (event) {
    event.stopPropagation();
    if (slideToggleIndicator == true) {
      slideToggleIndicator = false;
      var target = jq3(this).attr('data-slide');
      if (!jq3('#' + target).hasClass('active')) {
        jq3('#' + target).slideDown(250, function () {
          jq3(this).addClass('active');
          slideToggleIndicator = true;
        });
      } else {
        jq3('#' + target)
          .removeClass('active')
          .slideUp(250, function () {
            // jq3(this).removeClass('active');
            slideToggleIndicator = true;
          });
      }
    }
    if (jq3('#' + target).find('.styler').length) {
      setTimeout(function () {
        jq3('.styler').trigger('refresh');
      }, 100);
    }
    slideToggleIndicator;
  });
}
function overlayStart() {
  var wS = window.pageYOffset;
  jq3('body').addClass('overlay-open');
  jq3('body').css('top', -wS + 'px');
  /* 	console.log('fixed:  ' + wS); */
}
function overlayEnd() {
  var wS = Math.abs(parseInt(jq3('body').css('top')));
  jq3('body').css('top', '');
  jq3('body').removeClass('overlay-open');

  /* 	jq3('body').animate({ scrollTop: wS }, 0); */
  // setTimeout(() => {
  jq3(window).scrollTop(wS);

  // }, 0);

  /* 	console.log('unfixed:  ' + wS); */
}
function remove() {
  jq3(document).on('click', '*[data-remove]', function () {
    var target = jq3(this).attr('data-remove');
    jq3('#' + target).remove();
  });
}
function toggleTabsActiveClass() {
  jq3('.w-tab').on('click', function () {
    if (jq3(this).hasClass('active')) {
      return;
    } else {
      jq3(this).parents('.w-tab-parent').find('.w-tab').removeClass('active');
      jq3(this).addClass('active');
    }
  });
}
function toggleDisplayByTab() {
  jq3(document).on('click touch', '*[data-tab]', function () {
    var target = jq3(this).attr('data-tab');
    jq3(this).addClass('active').siblings('.w-tab').removeClass('active');
    jq3('#' + target)
      .addClass('active')
      .siblings('.w-tab-section')
      .removeClass('active');
    if (jq3('#' + target).find('.styler').length) {
      setTimeout(function () {
        jq3('.styler').trigger('refresh');
      }, 100);
    }
  });
}
function triggerClick() {
  jq3(document).on('click', '*[data-trigger]', function () {
    var target = jq3(this).attr('data-trigger');
    jq3('#' + target).trigger('click');
  });
}
function scrollToTop() {
  jq3('.w-scroll-top').click(function () {
    jq3('html,body').animate({ scrollTop: 0 }, 500);
  });
}
function scrollToElement() {
  jq3(document).on('click touch', '*[data-scroll]', function () {
    var additionalOffset = 0;
    if (jq3(this).hasClass('tabs__item')) {
      if (jq3('.tabs-gallery-placeholder').hasClass('is-sticky')) {
        additionalOffset =
          jq3('.main-header__sticky-wrapper').height() +
          jq3('.tabs-gallery-placeholder__sticky').height();
      } else {
        additionalOffset = jq3('.main-header__sticky-wrapper').height();
      }
    }
    var target = jq3(this).attr('data-scroll');
    var targetOffsetTop = jq3('#' + target).offset().top;
    /* 		var wS = window.pageYOffset;
		var scrollDistance = targetOffsetTop + wS; */
    jq3('html, body').animate(
      { scrollTop: targetOffsetTop - additionalOffset },
      250
    );
  });
}
