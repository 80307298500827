// jq3(document).ready(function () {
jq3(document).on('keyup', '.js-livesearch-input', function () {
  var filter = jq3(this).val();
  jq3('.js-livesearch-item').each(function () {
    if (
      jq3(this)
        .find('.js-livesearch-txt')
        .text()
        .search(new RegExp(filter, 'i')) < 0
    ) {
      jq3(this).removeClass('active');
    } else {
      jq3(this).addClass('active');
    }
  });
});

jq3(document).on('keyup', '*[data-livesearch-state]', function () {
  let target = jq3(this).attr('data-livesearch-state');
  if (jq3(this).val() == '') {
    jq3('#' + target).removeClass('empty');
  }

  if (jq3('#' + target).find('.js-livesearch-item.active').length !== 0) {
    jq3('#' + target).removeClass('empty');
  } else {
    jq3('#' + target).addClass('empty');
  }
});


// bdg - handle automation submission of form on filtering
jq3(document).ready(function() {

  var delay = 200;

  function submitCalendarForm() {

    var loaderDiv = "<div class='msr-loader'><div class='msr-loader-content'><div><img src='/assets/svg/h-loader-anim-css.svg' /></div><h2>Looking for fun things to do...</h2></div></div>";

		jq3("body").prepend(loaderDiv);
		jq3('html, body, .modal', parent.document).animate({
		  scrollTop: 0
		}, 300);

    $('#calendarForm').submit();
  }

  // defined in locationAutocomplete.js
  jq3('form.w-event-search').each(function() {
    if (autocomplete) { 
      autocomplete.addListener("place_changed", function() {
        console.log('place changed!!!');
        setTimeout(submitCalendarForm, delay);
      });
    }
  });

  jq3('#event-miles').change(function() { 
    setTimeout(submitCalendarForm, delay);
  });

  jq3('form.w-event-search button.js-display-checked-value-btn, form.w-event-search button.form-item-actions__btn--reset').click(function() {
    setTimeout(submitCalendarForm, delay);
  });

})