jq3(document).on('click', '.tabs__item', function(){
    jq3('.main-header').addClass('hidden');
    let timerId = setInterval(() => {
        jq3('.main-header__sticky-wrapper').removeClass('is-sticky is-active display-block');
        jq3('.tabs-gallery-placeholder').removeClass('is-active is-sharp');
    }, 0.001);
    setTimeout(() => {
            clearInterval(timerId);
            jq3('.main-header').removeClass('hidden');
    }, 500);
});