const initState = () => {
  const $mainItem = jq3('.es-filters-select__item');

  $mainItem.each(function () {
    const $value = jq3(this).find('.es-filters-select__value');
    const valueId = $value.attr('data-drop');
    const $checkedItems = jq3(this).find('input:checked');

    if (valueId) {
      ES_FILTER_STATE[valueId] = [];

      $checkedItems.each(function () {
        if (this.checked) {
          ES_FILTER_STATE[valueId].push({
            value: this.value,
            label: jq3(this).siblings('.checkbox__txt').text().trim(),
          });
        }
      });
    }
  });
};

const displayCheckedValues = ($item) => {
  const $mainItem = $item.closest('.es-filters-select__item');
  const valueId = $item.attr('data-drop');
  const $checkItems = $mainItem.find('input[type="checkbox"]');

  if (valueId) {
    ES_FILTER_STATE[valueId + '_clear'] = false;
    $checkItems.each(function () {
      if (isInState(valueId, jq3(this).val())) {
        jq3(this).prop('checked', true);
      } else {
        jq3(this).prop('checked', false);
      }
    });
  }
};

const commonPartOfDisplayCheckedValue = (item) => {
  let res = resolutionCheck(),
    target = jq3('#' + item.attr('data-display-checked-value')),
    defaultValue = target.attr('data-display-value-default');

  if (res >= 120) {
    if (item.find('.checkbox input:checked').length > 1) {
      target
        .text(defaultValue)
        .siblings('.es-filters-select__value-numb')
        .addClass('active')
        .text(item.find('.checkbox input:checked').length);
    } else if (item.find('.checkbox input:checked').length == 1) {
      let singleCheckedTxt = item
        .find('.checkbox input:checked')
        .siblings('.checkbox__txt')
        .text();
      target
        .text(singleCheckedTxt)
        .siblings('.es-filters-select__value-numb')
        .removeClass('active')
        .text(1);
    } else {
      target
        .text(defaultValue)
        .siblings('.es-filters-select__value-numb')
        .removeClass('active');
    }
  } else {
    target
      .text(defaultValue)
      .siblings('.es-filters-select__value-numb')
      .removeClass('active');
  }
};

const displayCheckedValueOnLoad = () => {
  let checkedList = jq3('*[data-display-checked-value]');
  checkedList.each(function () {
    commonPartOfDisplayCheckedValue(jq3(this));
  });
};

const displayCheckedDistance = () => {
  jq3('.es-filters-select__value--styler').each(function () {
    let defaultValue = jq3(this).find(
      '.jq-selectbox__dropdown .js-default-distance'
    );
    if (defaultValue.hasClass('selected')) {
      jq3(this).removeClass('checked');
    } else {
      jq3(this).addClass('checked');
    }
  });
};

jq3(document).ready(function () {
  initState();
  displayCheckedValueOnLoad();
});
jq3(window).on('resize orientationchange', function () {
  displayCheckedValueOnLoad();
});
jq3(window).on('load', function () {
  displayCheckedDistance();
});

/* ------------------------------- Save button ------------------------------ */
jq3(document).on('click touch', '.js-display-checked-value-btn', function () {
  let arr = jq3(this).attr('data-display-checked-value-btn'),
    target = arr.split(' ');
  for (var i = 0; i < target.length; i++) {
    let checkedList = jq3('#' + target[i]);
    commonPartOfDisplayCheckedValue(checkedList);
  }
});

/* -------------------------- Cross button distance ------------------------- */
jq3(document).on(
  'click touch',
  '.es-filters-select__value .jq-selectbox__dropdown li, .es-filters-select__value__remove.js-default-distance',
  function () {
    if (!jq3(this).hasClass('js-default-distance')) {
      jq3(this).closest('.es-filters-select__value').addClass('checked');
    } else {
      jq3(this).closest('.es-filters-select__value').removeClass('checked');
    }
    if (jq3(this).hasClass('es-filters-select__value__remove')) {
      jq3(
        // это усложнение на всякий случай, если когда-нибудь появтся ещё аналогичные селекты
        // чтобы считывался клик именно внутри данного стайлера
        jq3(this)
          .closest('.es-filters-select__value')
          .find('li.js-default-distance')
          .trigger('click')
      );
    }
  }
);

/* ------------------------------ Cross button ------------------------------ */
jq3('.es-filters-select__value__remove:not(.js-default-distance)').on(
  'click touch',
  function (e) {
    e.stopPropagation();

    jq3(this)
      .closest('.es-filters-select__item')
      .find('input[type="checkbox"]')
      .each(function () {
        jq3(this).prop('checked', false);
      });
    setTimeout(
      (() => {
        jq3(this)
          .closest('.es-filters-select__item')
          .find('.js-display-checked-value-btn')
          .trigger('click');
      }).bind(this),
      10
    );
  }
);

/* --------------------------- Filter item button --------------------------- */
jq3('.es-filters-select__value').on('click touch', function () {
  const isModal = jq3(this).closest('.mdl-wrap').length !== 0;
  if (isModal) return void 0;

  displayCheckedValues(jq3(this));
});

jq3('.es-filters-select__mdl-action').on('click touch', function () {
  const target = '#' + jq3(this).attr('data-open');
  const targetItems = jq3(target).find('.es-filters-select__value');

  targetItems.each(function () {
    displayCheckedValues(jq3(this));
  });
});