function highlightCol(e) {
  const index = jq3(this).index();

  if (index === 0) return void 0;

  const target = jq3(this)
    .closest('table')
    .find('th, td')
    .filter(`:nth-child(${index + 1})`);

  if (e.type == 'mouseover') {
    target.addClass('highlight');
  } else {
    target.removeClass('highlight');
  }
}

const transformTable = ($table, event) => {
  const headers = $table.find('th').filter(':not(:first-child)'),
    rows = $table.find('tbody tr').toArray(),
    footerRows = $table.find('tfoot tr').toArray();

  if (event === 'destroy') {
    if ($table.hasClass('price-table--transform')) {
      jq3(headers).each(function () {
        const value = jq3(this).data('titleShort') || jq3(this).data('title') || '';
        jq3(this).text(value);
      });

      $table.removeClass('price-table--transform');
    }

    return void 0;
  }

  const statusList = Array.from(Array(headers.length)).map((e, i) => {
    const $title = jq3('<div />', {
      class: 'price-card__title',
      html: jq3(headers[i]).data('title') || jq3(headers[i]).text(),
    });
    const statusList = jq3('<div class="price-card"></div>').append(
      $title,
      '<div class="price-card__items-list"></div>',
      '<div class="price-card__footer"></div>'
    );

    return statusList;
  });

  for (let row of rows) {
    for (let cellIndex = 1; cellIndex < row.cells.length; cellIndex++) {
      const cell = row.cells[cellIndex];
      const $rowLabel = jq3(row).find('.price-table__row-name').clone();
      const $rowDesk = jq3(row).find('.price-table__row-desk').clone();
      const $cellStatus = jq3(cell).find('.price-table__value').clone();
      const $cellStatusExtra = jq3(cell)
        .find('.price-table__extra-value')
        .clone();

      const $cellStatusText = $cellStatus.find('span');
      if ($cellStatusText.length) {
        const rowName = $rowLabel.find('span').text();
        const cellValueText = $cellStatusText.text();
        $cellStatusText.remove();
        $rowLabel
          .find('span')
          .text(cellValueText + ' ' + rowName.toLowerCase());
      }

      if ($cellStatusExtra.length) {
        const $statusExtra = jq3('<div class="price-card__item"></div>').append(
          $cellStatusExtra
        );

        jq3(statusList[cellIndex - 1])
          .find('.price-card__items-list')
          .append($statusExtra);
      }

      if ($cellStatus.length) {
        const $status = jq3('<div class="price-card__item"></div>').append(
          $cellStatus,
          $rowLabel,
          $rowDesk
        );

        jq3(statusList[cellIndex - 1])
          .find('.price-card__items-list')
          .append($status);
      }
    }
  }

  for (let row of footerRows) {
    for (let cellIndex = 1; cellIndex < row.cells.length; cellIndex++) {
      const cell = row.cells[cellIndex];
      const $footerCard = jq3(cell).find('.pricing-plan').clone();
      $footerCard.find('.pricing-plan__name').remove();

      jq3(statusList[cellIndex - 1])
        .find('.price-card__footer')
        .append($footerCard);
    }
  }

  for (let i = 0; i < headers.length; i++) {
    jq3(headers[i]).html('').append(statusList[i]);
  }

  $table.addClass('price-table--transform');
};

const initTable = () => {
  const $table = jq3('.price-table');

  if ($table.length) {
    const res = resolutionCheck();
    const $popoverText = jq3('#pt-popover').find('.popover__txt');
    $popoverText.text('');

    $table.on('click touch', '.price-table__row-name', function (e) {
      const rowDesk = jq3(this).siblings('.price-table__row-desk').text();
      $popoverText.text(rowDesk);
    });

    if (res <= 76) {
      $table.off('mouseover mouseleave', 'th, td', highlightCol);
      transformTable($table);
    } else {
      transformTable($table, 'destroy');
      $table.on('mouseover mouseleave', 'th, td', highlightCol);
    }
  }
};

jq3(document).ready(function () {
  initTable();
});

jq3(window).on('resize orientationchange', function () {
  initTable();
});
