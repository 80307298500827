/* version for closest parent with no siblings js-accord */

jq3(document).on('click touch', '.js-accord-head', function () {
  let parent = jq3('.js-accord'),
    btn = jq3('.js-accord-head'),
    body = jq3('.js-accord-body'),
    btnInsideClosestWrap = jq3(this).closest('.js-accord-wrap').find(btn);

  btnInsideClosestWrap.not(jq3(this)).each(function () {
    jq3(this).removeClass('active');
    if (jq3(this).siblings(body).length) {
      jq3(this).siblings(body).slideUp().removeClass('active');
    }

    jq3(this).closest(parent).removeClass('active');
    if (jq3(this).find('.jq-selectbox__dropdown').length) {
      jq3(this).find('.jq-selectbox__dropdown').slideUp();
    }
  });

  jq3(this).toggleClass('active').siblings(body).slideToggle();
  jq3(this).closest(parent).toggleClass('active');

  if (jq3(this).siblings(body).find('.styler').length) {
    refreshStyler();
  }
  if (jq3(this).siblings(body).find('.js-filter-list-multiple-columns').length) {
    filterListColumnsHeight();
    setTimeout(() => {
      filterListColumnsHeight();
    }, 20);
  }
});