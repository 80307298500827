var isIE = '-ms-scroll-limit' in document.documentElement.style && '-ms-ime-align' in document.documentElement.style;

function androidDeviceDetection() {
    var ua = navigator.userAgent.toLowerCase();
    var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
    if (isAndroid) {
        jq3('body').addClass('android');
    }
}

jq3(document).ready(function () {
    if (isIE) {
        jq3('html').addClass('ie-11');
    }
    androidDeviceDetection();
});