/**************FORM-ELEMENTS***********/
function formItemSplitHovered(){
	jq3('.btn-group .btn--drop-split').hover(
		function(){jq3(this).siblings('.btn').addClass('hovered')},
		function(){jq3(this).siblings('.btn').removeClass('hovered')}
	);
}
function formItemFocused(){
	jq3(document).on('focus', '.form-item, .ui-multiselect', function(){
		jq3(this).parent('.form-item-wrap').addClass('focused');
	})
	jq3(document).on('focusout', '.form-item, .ui-multiselect', function(){
		jq3(this).parent('.form-item-wrap').removeClass('focused');
	})
}
function removeErrorFromFormItem(){
	jq3(document).on('change input', '.form-item', function(){
		jq3(this).closest('.form-item-wrap').removeClass('error success warning');
		jq3(this).closest('.form-cell').find('.form-cell__sign').removeClass('active')
	});
	jq3(document).on('change', 'input[type="checkbox"], input[type="radio"]', function(){
		jq3(this).parent().removeClass('error success warning');
	});
}
function showOnCheck(){
	jq3('*[data-check]').each(function(){
		var target = jq3(this).attr('data-check');
		if(jq3(this).prop('checked')){
			jq3('#'+target).addClass('active');
		}
		else{
			jq3('#'+target).removeClass('active');
		}
	});
	jq3(document).on('click', '*[data-check]', function(){
		var target = jq3(this).attr('data-check');
		if(jq3(this).prop('checked')){
			jq3('#'+target).addClass('active');
			if(jq3('#'+target).find('.styler').length){
				refreshStyler();
			}
		}
		else{
			jq3('#'+target).removeClass('active');
		}
	});
}
function showOnRadioCheck(){
	jq3(document).on('change', '*[data-radio]', function(){
		var target = jq3(this).attr('data-radio');
		if(jq3(this).is(':checked') && jq3(this).val() == '1'){
			jq3('#'+target).addClass('active');
		}
		else {
			jq3('#'+target).removeClass('active');
		}
	});
}
function formItemActiveDisable(){
	jq3('*[data-disable]').each(function(){
		var target = jq3(this).attr('data-disable');
		if(jq3(this).prop('checked')){
			jq3('#'+target).prop('disabled', false);
			jq3('#'+target).parent('.form-item-wrap').removeClass('disabled');
		}
		else{
			jq3('#'+target).prop('disabled', true);
			jq3('#'+target).parent('.form-item-wrap').addClass('disabled');
		}
	});
	jq3(document).on('click', '*[data-disable]', function(){
		var target = jq3(this).attr('data-disable');
		if(jq3(this).prop('checked')){
			jq3('#'+target).prop('disabled', false);
			jq3('#'+target).parent('.form-item-wrap').removeClass('disabled');
		}
		else{
			jq3('#'+target).prop('disabled', true);
			jq3('#'+target).parent('.form-item-wrap').addClass('disabled');
			jq3('#'+target).val('');
		}
	});
}
function formItemInputNumCheck(){
	if(jq3('input.w-num').length > 0){
		jq3('input.w-num').bind("change keyup input click", function() {
			if (this.value.match(/[^0-9]/g)) {
				this.value = this.value.replace(/[^0-9]/g, '');
			}
		});
	}
}
function formItemInputNumDotCheck(){
	if(jq3('input.w-num-dot').length > 0){
		jq3('input.w-num-dot').bind("change keyup input click", function() {
			if (this.value.match(/[^.\d]+/g)) {
				this.value = this.value.replace(/[^.\d]+/g, '');
			}
		});
	}
}
function formItemInputNumCommaCheck(){
	if(jq3('input.w-num-comma').length > 0){
		jq3('input.w-num-comma').bind("change keyup input click", function() {
			if (this.value.match(/[^(?:\d\,]+/g)) {
				this.value = this.value.replace(/[^(?:\d\,]+/g, '');
			}
		});
	}
}
function formItemInputNumDotMinusCheck(){
	if(jq3('input.w-num-dot-minus').length > 0){
		jq3('input.w-num-dot-minus').bind("change keyup input click", function() {
				if (this.value.match(/[^\-\.\d]+/g)) {
					this.value = this.value.replace(/[^\-\.\d]+/g, '');
				}
			}
		);
	}
}
function formItemEmailCheck(){
	jq3(document).on("blur change keyup input", 'input[type="email"]', function() {
		if (this.value.match(/^([a-z0-9_\-]+\.)*[a-z0-9_\-]+@([a-z0-9][a-z0-9\-]*[a-z0-9]\.)+[a-z]{2,4}$/i)) {
			//если email валиден
		}
		else {
			//если email не валиден
		}
	});
}
function preventKeyboardTriggeringOnMobile(){
	if(jq3('.touchevents').length) {
		/* Do not trigger keyboard popup on iOS*/
		jq3(document).on('mousedown', 'input[readonly]', function (e) {
			e.preventDefault();
		});
	}
}
function refreshStyler(){
	setTimeout(function() {
		jq3('.styler').trigger('refresh');
	}, 100)
}
function styler(){
	if(jq3('.styler').length > 0){
		jq3('.styler:not(.filter)').styler({
			locale: 'en'
		});
	}
	if(jq3('.styler.filter').length > 0){
		jq3('.styler.filter').styler({
			locale: 'en',
			selectSearch: true,
			selectSearchLimit: 10,
			selectSearchPlaceholder: "Enter Keyword"
		});
	}
}
function multiselect(){
	if(jq3('.multiselect').length){
		jq3(".multiselect").multiselect({
			selectedText: "# out of # selected",
			multiple:true,
			height: 268
		})
	}
	if(jq3('.multiselect.filter').length){
		jq3(".multiselect.filter").multiselect().multiselectfilter({
			selectedText: "# out of # selected",
			multiple:true,
			height: 268
			// position: {
			// 	my: "left top",
			// 	at: "left bottom+20",
			// 	collision: "flipfit"
			// }
		})
	}
}
// jq3(document).on('click', '.ui-multiselect-all', function(){
// 	jq3(this).closest('.ui-multiselect-menu').find('.ui-multiselect-checkboxes input').each(function(){
// 		jq3(this).attr('aria-selected', 'true').attr('checked', 'true');
// 	});
// });
// jq3(document).on('click', '.ui-multiselect-none', function(){
// 	jq3(this).closest('.ui-multiselect-menu').find('.ui-multiselect-checkboxes input').each(function(){
// 		jq3(this).removeAttr('aria-selected').removeAttr('checked');
// 	});
// });


//для проверки форм при помощи validate.js
//в плагинах нужно подключить
//jquery.validate.min.js'
//additional-methods.min.js'

//обязательному полю добавляется аттрибут required
//надо выносить этот скрипт непосредственно на страницу, где форма
var validateSettings = {
	focusCleanup: true,
	focusInvalid: false,
	onkeyup: function (element, event) {
		jq3(element).parent('.form__item-wrap').removeClass('error');
	},
	errorPlacement: function(error, element){
		//добавления стиля ошибки
		jq3(element).parent('.form__item-wrap').addClass('error');
		return true;
	}
};
// jq3('#form').validate(validateSettings);
