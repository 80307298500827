const transformFiltersToAccordionOnMobile = () => {
  let item = jq3('.es-filters-select__value'),
    res = resolutionCheck();
  item.each(function () {
    if (jq3(this).closest('.filter-list-wrap-modal').length) {
      jq3(this).addClass('js-accord-head accord__head');
      jq3(this)
        .siblings('.js-event-drop-body')
        .addClass('js-accord-body accord__body')
        .removeClass('w-dropdown dropdown dropdown--event');
      jq3(this).closest('.es-filters-select__item').addClass('js-accord accord');
    } else {
      jq3(this).removeClass('js-accord-head accord__head');
      jq3(this)
        .siblings('.js-event-drop-body')
        .removeClass('js-accord-body accord__body')
        .addClass('w-dropdown dropdown dropdown--event');
      jq3(this)
        .closest('.es-filters-select__item')
        .removeClass('js-accord accord');
    }
  });
};

const transformToAccordionInit = () => {
  if (jq3('.es-filters-select__value').length) {
    jq3(document).ready(function () {
      transformFiltersToAccordionOnMobile();
    });
    jq3(window).on('load resize orientationchange', function () {
      transformFiltersToAccordionOnMobile();
    });
  }
};
transformToAccordionInit();
