const hideSearchbox = () => {
  jq3('[data-drop="dropdown-searchbox"]').removeClass('active');
  jq3('#dropdown-searchbox').removeClass('active');
};

const setHeaderHeightCss = () => {
  var mH = jq3('.main-header__sticky-wrapper');
  var mHeight = mH.outerHeight();
  jq3('body').css('padding-top', mHeight + 'px');
};


var direction;
function mainHeaderPosition(direction) {
  if (!jq3('.mh-navbar--main').hasClass('active')) {
    var wS = jq3(window).scrollTop();
    var mH = jq3('.main-header__sticky-wrapper');
    var mHeight = mH.outerHeight();
    if (wS > mHeight) {
      mH.addClass('is-sticky');
      if (direction == 1) {
        mH.addClass('display-block');
        setTimeout(function () {
          mH.addClass('is-active');
        }, 50);
      } else {
        hideSearchbox();
        mH.removeClass('is-active');
        setTimeout(function () {
          mH.removeClass('display-block');
        }, 50);
      }
    } else if (wS < 1) {
      mH.removeClass('is-active display-block is-sticky');
    }
  }
}

jq3(window).on('load', function () {
  direction = 1;
  mainHeaderPosition(direction);
  // we are applying a body.loggedin/loggedout class to avoid the annoying jump after page load caused by this: setHeaderHeightCss();
});

jq3(window).on('scroll', function () {
  var st = jq3(this).scrollTop();
  if (st > lastScrollTop) {
    // downscroll
    direction = 0;
    mainHeaderPosition(direction);
  } else {
    // upscroll
    direction = 1;
    mainHeaderPosition(direction);
  }
  lastScrollTop = st;
});

jq3(document).on('click', '.mh-navbar__toggler', function () {
  jq3('.main-header__sticky-wrapper').removeClass(
    'is-sticky display-block is-active'
  );
});

jq3(window).on('resize orientationchange', function () {
  var res = resolutionCheck();
  setHeaderHeightCss();
  if (res === 32) hideSearchbox();
});
