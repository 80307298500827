const saveFilterValues = ($item, type) => {
  const $value = $item.find('.es-filters-select__value');
  const valueId = $value.attr('data-drop');
  const $checkedItems = $item.find('input:checked');

  if (valueId && type !== 2) {
    ES_FILTER_STATE[valueId] = [];

    if (!ES_FILTER_STATE[valueId + '_clear']) {
      $checkedItems.each(function () {
        const value = this.value.toString();

        if (this.checked) {
          ES_FILTER_STATE[valueId].push({
            value: value,
            label: jq3(this).siblings('.checkbox__txt').text().trim(),
          });
        } else {
          ES_FILTER_STATE[valueId] = ES_FILTER_STATE[valueId].filter(
            (stateValue) => stateValue.value !== value
          );
        }
      });
    }

    ES_FILTER_STATE[valueId + '_clear'] = false;
  }

  if (valueId && type === 2) {
    ES_FILTER_STATE[valueId + '_clear'] = true;
  }
};

const markFilterSelectValueAsChecked = (item, type) => {
  if (
    item.find('input:checked').prop('checked') == true ||
    item.find('li.selected').length ||
    item.find('.form-item-wrap--event-location').length
  ) {
    item.find('.es-filters-select__value').addClass('checked');
  } else {
    if (type == 1) {
      item.find('.es-filters-select__value').removeClass('checked');
    }
  }
};

const markFilterSelectValueAsCheckedByLoad = () => {
  let item = jq3('.es-filters-select__item');

  item.each(function () {
    markFilterSelectValueAsChecked(jq3(this));
  });
};

jq3(document).ready(function () {
  markFilterSelectValueAsCheckedByLoad();
});

jq3(document).on('click touch', '.js-check-all-item-value-state', function () {
  markFilterSelectValueAsCheckedByLoad();
 
  $('.es-filters-select__item').each(function () {
    saveFilterValues($(this));
  });  
});

jq3(document).on('click touch', '.js-check-item-value-state', function () {
  let item = jq3(this).closest('.es-filters-select__item'),
    type = 0;
  if (jq3(this).hasClass('js-display-checked-value-btn')) {
    type = 1;
  }
  if (jq3(this).attr('data-clear')) {
    type = 2;
  }
  markFilterSelectValueAsChecked(item, type);
  saveFilterValues(item, type);
});
