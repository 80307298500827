function filterListColumnsHeight() {
  let itemWrap = jq3('.js-filter-list-multiple-columns');
  if (itemWrap.length > 0) {
    let res = resolutionCheck(),
      col,
      item = itemWrap.find('.js-filter-item-multiple-columns'),
      itemLength = itemWrap.find('.js-filter-item-multiple-columns.active')
        .length,
      itemHeight = item.outerHeight(),
      div,
      divInt;
    if (res == 76) {
      col = 2;
      div = itemLength / col;
      divInt = parseInt(itemLength / col);
      if (div > divInt) {
        itemWrap.css('max-height', divInt * itemHeight + itemHeight + 'px');
      } else {
        itemWrap.css('max-height', divInt * itemHeight + 'px');
      }
    } else {
      itemWrap.css('max-height', 'none');
    }
  }
}

jq3(document).ready(function () {
  filterListColumnsHeight();
});
jq3(window).on('load resize orientationchange', function () {
  filterListColumnsHeight();
});
jq3(document).on('click touch', '.es-filters-select__mdl-action', function () {
  filterListColumnsHeight();
});
jq3(document).on('keyup', '.js-column-height-by-search', function () {
  let res = resolutionCheck();
  if (res == 76) {
    setTimeout(function () {
      filterListColumnsHeight();
    }, 50);
  }
});
