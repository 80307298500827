const initSlick = (target, bp) => {
  let responsive = null;
  const bpAbove = {
    168: 1920,
    144: 1680,
    120: 1440,
    76: 1200,
    32: 768,
  };

  if (bp) {
    responsive = bp.map((res) => ({
      breakpoint: bpAbove[res.res],
      settings: {
        slidesToShow: res.qty || 1,
        slidesToScroll: res.qty || 1,
      },
    }));
  }

  jq3(target).slick({
    arrows: false,
    dots: true,
    dotsClass: 'slider-dash-dots',
    adaptiveHeight: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive,
  });
};

const initSlickByRes = (res) => {
  jq3('.w-slick').each(function () {
    const isSlick = jq3(this).hasClass('slick-initialized');
    let dataRes = jq3(this).data('slickRes');
    const resArr = [];

    if (dataRes) {
      dataRes = dataRes.toString().split(';');

      dataRes = dataRes.map((res) => {
        let resSplit = res.split(':');
        resArr.push(resSplit[0]);

        return {
          res: parseInt(resSplit[0]),
          qty: parseInt(resSplit[1]),
        };
      });
    }

    const shouldInit = res ? resArr.includes(res.toString()) : false;

    if (shouldInit && !isSlick) {
      initSlick(this, dataRes);
    }

    if (!shouldInit && isSlick) {
      jq3(this).slick('unslick');
    }
  });
};

const slickTestimonials = () => {
  jq3('.testimonials-carousel__images-wrap').slick({
    asNavFor: '.testimonials-carousel__testimonial',
    prevArrow: jq3('.slick-prev'),
    nextArrow: jq3('.slick-next'),
    dots: true,
    dotsClass: 'slider-dots',
    fade: true,
    adaptiveHeight: true,
  });

  jq3('.testimonials-carousel__testimonial').slick({
    asNavFor: '.testimonials-carousel__images-wrap',
    arrows: false,
    fade: true,
    adaptiveHeight: true,
  });
};

jq3(document).ready(function () {
  var res = resolutionCheck();
  initSlickByRes(res);
});

jq3(window).on('resize orientationchange', function () {
  if (jq3('.w-slick').length) {
    var res = resolutionCheck();
    initSlickByRes(res);
  }
});
