jq3(document).ready(function () {
  stopPropagation();
  resolutionCheck();
  openDataOpen();
  closeDataClose();
  closeMDL();
  drop();
  closeDropdown();
  scrollToTop();
  scrollToElement();
  triggerClick();
  toggleTabsActiveClass();
  toggleDisplayByTab();
  objectFitImages();
  toggle();
  slideToggleFunction();
  playVideo();
  tooltipToggle();

  formItemSplitHovered();
  formItemFocused();
  showOnCheck();
  showOnRadioCheck();
  formItemActiveDisable();
  refreshStyler();
  formItemInputNumCheck();
  formItemInputNumDotCheck();
  formItemInputNumCommaCheck();
  formItemInputNumDotMinusCheck();
  formItemEmailCheck();
  preventKeyboardTriggeringOnMobile();
  removeErrorFromFormItem();
  styler();
  multiselect();

  svg4everybody();

  /* JS for Hagerty only */
  closeHagerty();

  // jq3(window).on('load', function () {});

  // jq3(window).on('scroll', function () {
  //   var st = jq3(this).scrollTop();
  //   if (st > lastScrollTop) {
  //     // downscroll
  //   } else {
  //     // upscroll
  //   }
  //   lastScrollTop = st;
  // });

  // jq3(window).on('resize orientationchange', function () {
  //   var res = resolutionCheck();
  // });
});
