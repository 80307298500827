// choose location

/* this is handled in the initAutocompleteLocation callback 
jq3(document).on('mousedown touchstart', '.pac-item', function () {
  console.log('text of this node = ' + jq3(this).text());
  jq3(this)
    .closest('.es-filters-select__item')
    .find('.es-filters-select__value__label')
    .text(jq3(this).text());
});
*/


//  choose distance
const cutSelectValue = () => {
  let item = jq3('.es-filters-select__value--styler').find(
      '.jq-selectbox__select-text'
    ),
    itemNewText = item.text().replace('Within', '');
  item.text(itemNewText);
};

jq3(window).on('load', function () {
  cutSelectValue();
});

jq3(document).on(
  'click touch',
  '.es-filters-select__value--styler .jq-selectbox__dropdown li',
  function () {
    let textToInsert = jq3(this).text().replace('Within ', '');
    jq3(this)
      .closest('.es-filters-select__value')
      .find('.jq-selectbox__select-text')
      .text(textToInsert);
  }
);
