
var itemsToCompare = ['.w-item-to-wrap:not(.w-eq-complete)'];

function elementsPerRow(items) {
    var baseOffset = jq3(items)
        .eq(0)
        .position().top;
    var breakIndex = -1;
    jq3(items).each(function (i) {
        if (jq3(this).position().top > baseOffset) {
            breakIndex = i;
            return false;
        }
    });
    var numPerRow = breakIndex === -1 ? jq3(items).length : breakIndex;
    return numPerRow;
}

function wrapItems(eWrap, element, first, last) {
    var item = eWrap.find(element);
    item.find('.w-eq-height').css('height', 'auto');
    item.each(function () {
        var itemEq = eWrap.children(element).slice(first, last);
        itemEq.addClass('equalized');
        jq3('.w-eq-height-items-wrap > .equalized').wrapAll('<div></div>');
    });
}

function sortingItems() {
    var itemsWrap = jq3('.w-eq-height-items-wrap');
    var sliceNumb;
    var element;

    itemsWrap.each(function () {
        // sliceNumb = elementsPerRow(jq3(this).children());
        sliceNumb = elementsPerRow(jq3(this).children('.w-item-to-wrap'));
        element = itemsToCompare[0];
        wrapItems(jq3(this), element, 0, sliceNumb);
    });
}

function setEqHeightInRow(eToEq) {
    jq3('.w-eq-height-items-wrap > *:not(.w-item-to-wrap)').each(function () {
        var itemEq = jq3(this).find(eToEq);
        var maxH = itemEq.eq(0).height();
        itemEq.each(function () {
            maxH = jq3(this).height() > maxH ? jq3(this).height() : maxH;
        });
        itemEq.height(maxH);
    });
    jq3('.w-eq-height-items-wrap .equalized')
        .removeClass('equalized')
        .addClass('w-eq-complete')
        .unwrap();
}

function startEqHieghtScript() {
    if (jq3('.w-eq-height-items-wrap').length) {
        sortingItems();
        setEqHeightInRow('.w-eq-height');
    }
}


jq3(window).on('load', function () {
    if (jq3('.w-item-to-wrap').length) {
        setTimeout(function () {
            startEqHieghtScript();
        }, 50);
    }
});
jq3(window).on('resize orientationchange', function () {
    if (jq3('.w-eq-complete').length) {
        jq3('.w-eq-complete').removeClass('w-eq-complete');
    }
    if (jq3('.w-item-to-wrap').length) {
        setTimeout(function () {
            startEqHieghtScript();
        }, 50);
    }
});
jq3(window).on('scroll', function () {
    if (jq3('.w-item-to-wrap').length) {
        startEqHieghtScript();
    }
});





/* simple function for  card__txt*/
function simpleEqHeight() {
    var maxH = 0;
    jq3('.card-membership__txt').each(function () {
        maxH = jq3(this).height() > maxH ? jq3(this).height() : maxH;
    });
    jq3('.card-membership__txt').height(maxH);
}

jq3(window).on('load', function () {
    if (jq3('.card-membership__txt').length) {
        simpleEqHeight();
    }
});
jq3(window).on('resize orientationchange', function () {
    if (jq3('.card-membership__txt').length) {
        simpleEqHeight();
    }
});
/* jq3(window).on('scroll', function () {
    if (jq3('.w-item-to-wrap').length) {
        startEqHieghtScript();
    }
    if (jq3('.card-membership__txt').length) {
        simpleEqHeight();
    }
}); */






/* var itemsToCompare = [
    '.w-item-to-wrap:not(.w-eq-complete)'
];

function wrapItems(eWrap, element, first, last) {
    var item = eWrap.find(element);
    item.find('.w-eq-height').css('height', 'auto');
    item.each(function () {
        var itemEq = eWrap.children(element).slice(first, last);
        itemEq.addClass('equalized');
        jq3('.w-eq-height-items-wrap > .equalized').wrapAll('<div></div>');
    });
}

function sortingItems(res) {
    var res = res;
    var itemsWrap = jq3('.w-eq-height-items-wrap');
    var sliceNumb;
    var element;
    itemsWrap.each(function () {
        if (jq3(this).hasClass('js-card-membership-row')) {
            if (res > 120) {
                sliceNumb = 3;
            }
            console.log('js-card-membership-row');
        }
        if (jq3(this).hasClass('car-for-sale-wrap')) {
            if (res > 32) {
                sliceNumb = 2;
            }
            console.log('car-for-sale-wrap');
        }
        element = itemsToCompare[0];
        wrapItems(jq3(this), element, 0, sliceNumb);
    });
}

function setEqHeightInRow() {
    jq3('.w-eq-height-items-wrap > *:not(.w-item-to-wrap)').each(function () {
        var itemEq = jq3(this).find('.w-eq-height');
        var maxH = itemEq.eq(0).height();
        itemEq.each(function () {
            maxH = (jq3(this).height() > maxH) ? jq3(this).height() : maxH;
        });
        itemEq.height(maxH);
    });
    jq3('.w-eq-height-items-wrap .equalized')
        .removeClass('equalized')
        .addClass('w-eq-complete')
        .unwrap();
}

function startEqHieghtScript() {
    var res = resolutionCheck();
    if (jq3('.w-eq-height-items-wrap').length && res > 32) {
        sortingItems(res);
        setEqHeightInRow();
    }
}

jq3(window).on('load', function () {
    if (jq3('.w-item-to-wrap').length) {
        setTimeout(function () {
            startEqHieghtScript();
        }, 50);
    }
});
jq3(window).on('resize orientationchange', function () {
    if (jq3('.w-eq-complete').length) {
        jq3('.w-eq-complete').removeClass('w-eq-complete');
    }
    if (jq3('.w-item-to-wrap').length) {
        setTimeout(function () {
            startEqHieghtScript();
        }, 50);
    }
}); */