const ES_FILTER_STATE = {};

const isInState = (key, value) => {
  let isInState = false;

  if (ES_FILTER_STATE.hasOwnProperty(key)) {
    const stateValue = ES_FILTER_STATE[key].findIndex(
      (entry) => entry.value === value.toString()
    );
    if (stateValue !== -1) isInState = true;
  }

  return isInState;
};

const esInitFields = () => {
  const $fieldsContainer = jq3('.event-search__fields'),
    $field = $fieldsContainer.find('.form-item'),
    $fieldActionReset = $fieldsContainer.find('.form-item-actions__btn--reset');

  const checkIsEmpty = ($input) => {
    const $wrap = $input.parents('.form-item-wrap');
    const $btnSubmit = $wrap.find('.form-item-actions__btn--confirm');

    if (!$input.val()) {
      $wrap.addClass('empty');
      //$btnSubmit.attr('disabled', true);
    } else {
      $wrap.removeClass('empty');
      //$btnSubmit.attr('disabled', false);
    }
  };

  const handleReset = ($btn) => {
    const $input = $btn.parents('.form-item-wrap').find('.form-item');
    $input.val('');
    checkIsEmpty($input);
  };

  const initSearchFields = () => {
    $field.each(function () {
      checkIsEmpty(jq3(this));
    });
    $field.on('change keyup input click', function () {
      checkIsEmpty(jq3(this));
    });
    $fieldActionReset.on('click touch', function () {
      handleReset(jq3(this));
    });
  };

  initSearchFields();
};



const esInit = () => {
  if (jq3('.w-event-search').length) {
    let inititalRes;

    jq3(document).ready(function () {
      inititalRes = resolutionCheck();

      esInitFields();
      // initFilters(inititalRes);
    });

    jq3(window).on('resize orientationchange', function () {
      let res = resolutionCheck();

      if (
        (res === 32 && inititalRes > 32) ||
        (res > 32 && inititalRes === 32)
      ) {
        inititalRes = res;
        // initFilters(res);
      }
    });
  }
};

esInit();
