const cloneItemIntoPlace = () => {
  let res = resolutionCheck();
  jq3('.js-clone-item').each(function () {
    let arr = jq3(this).attr('data-clone').split(' '),
      mobileWrap = arr[0],
      desktopWrap = arr[1],
      initialRes = arr[2];
    if (res <= initialRes) {
      jq3(this).appendTo('#' + mobileWrap);
    } else {
      jq3(this).appendTo('#' + desktopWrap);
    }
  });
};

const cloneInit = () => {
  if (jq3('.js-clone-item').length) {
    jq3(document).ready(function () {
      cloneItemIntoPlace();
    });

    jq3(window).on('resize orientationchange', function () {
      cloneItemIntoPlace();
    });    
  }
};
cloneInit();
