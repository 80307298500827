jq3(document).ready(function(){
    if (jq3(".table-transform").length) {
        var headertext = [],
            headers = document.querySelectorAll(".table-transform th"),
/*             tablerows = document.querySelectorAll(".table-transform th"), */
            tablebody = document.querySelector(".table-transform tbody");
        for (var i = 0; i < headers.length; i++) {
            var current = headers[i];
            headertext.push(current.textContent.replace(/\r?\n|\r/, ""));
        }
        for (var i = 0, row; row = tablebody.rows[i]; i++) {
            for (var j = 0, col; col = row.cells[j]; j++) {
                col.setAttribute("data-th", headertext[j]);
            }
        }
    }
    if (jq3(".wp-block-table").length) {
        var headertext = [],
            headers = document.querySelectorAll(".wp-block-table table tr:first-child td"),
/*             tablerows = document.querySelectorAll(".wp-block-table table th"), */
            tablebody = document.querySelector(".wp-block-table table tbody");
        for (var i = 0; i < headers.length; i++) {
            var current = headers[i];
            headertext.push(current.textContent.replace(/\r?\n|\r/, ""));
        }
        for (var i = 1, row; row = tablebody.rows[i]; i++) {
            for (var j = 0, col; col = row.cells[j]; j++) {
                col.setAttribute("data-th", headertext[j]);
            }
        }
    }
});