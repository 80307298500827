jq3(document).on('click touch', '*[data-clear]', function () {
  let target = jq3(this).attr('data-clear');
  jq3('#' + target)
    .find('input[type="checkbox"]')
    .each(function () {
      jq3(this).prop('checked', false);
    });
});

jq3(document).on('click touch', '*[data-clear-all]', function () {
  let target = jq3(this).attr('data-clear-all');
  jq3('#' + target)
    .find('input[type="checkbox"]')
    .each(function () {
      jq3(this).prop('checked', false);
    });
  jq3('.js-default-distance').trigger('click');
});
