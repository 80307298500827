jq3(document).ready(function () {
  if (jq3('.form-item[data-ph]').length) {
    let inititalRes = resolutionCheck();

    jq3('.form-item[data-ph]').each(function () {
      const $input = jq3(this);
      const ph = $input.data('ph');
      const phS = $input.data('phShort');
      const phNative = $input.attr('placeholder');

      const updatePlaceholder = (res) => {
        if (res === 32) {
          $input.attr('placeholder', phS || phNative);
        } else {
          $input.attr('placeholder', ph || phNative);
        }
      };
      updatePlaceholder(inititalRes);

      jq3(window).on('resize orientationchange', function () {
        let res = resolutionCheck();

        if (
          (res === 32 && inititalRes > 32) ||
          (res > 32 && inititalRes === 32)
        ) {
          inititalRes = res;
          updatePlaceholder(res);
        }
      });
    });
  }
});
